
.mobile-header {
  display: none;
}



/* Phones */
@media only screen and (min-width : 320px) and (max-width :1025px)  {


  .mobile-header {
    display: block;
  }

  .mobile-logo-hanmburger-wrapper {
    height: @mobile-menu-height;
    background-color: @core-color__background;
    position: fixed;
    width: 100%;
    top: 0;
    left:0;
    z-index: 200;

  }

  .mobile-menu-holder {
    display: block;
    top: -110vh;
    height: auto;
    transition: all 1s;
    width: 100%;
    position: fixed;
    background: @core-color__grey;
    z-index: 110;
    ul {
      margin: 0;
      padding: 40px 0;
      li {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;
        a {
          color: @core-color__lightblue;
          height: 70px;
          font-size: 1.4em;
          display: block;
          margin: 0 auto;
          padding-top: 20px;
          text-transform: uppercase;
        }
      }
      .mobile-menu-enquire-now {
        font-weight: bold;
        background-color: @core-color__bronze;
      }
    }
  }
  .mobile-menu-active  {
    top: @mobile-menu-height;
    position: absolute;
  }

  #menu-icon-holder {
    position: absolute;
    right:25px;
    top: 10px;
    right: 7.1vw;
    top: 43px;
  }

  .evermore-brand {
    img {
      max-width: 100%;
    }
  }
}

@media only screen and (orientation : landscape) {

  .mobile-menu-holder {
    top: -135vh;
  }
  .mobile-menu-active  {
    top: @mobile-menu-height;
  }

}