@menu-color: @core-color__grey;

//fancy mobile  menu
#menu-icon-holder{
  // display: none;
  height: 30px;
  &:hover {
    cursor: pointer;
    cursor: hand;
  }
}
#nav-toggle {
  position: relative;
  top: 6px;
  cursor: pointer;
  height: 21px;
  height: 21px;
  float: right;

 }

#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 21px;
  background: @menu-color;
  position: absolute;
  display: block;
  content: '';

}
 #nav-toggle span{
    top: 10px;

 }
#nav-toggle span:before {
  top: -7px;
}
#nav-toggle span:after {
  bottom: -7px;
}

#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {
  transition: all 500ms ease-in-out;
}
#nav-toggle.active span {
  background-color: transparent;
}
#nav-toggle.active span:before, #nav-toggle.active span:after {
  top: 0;
}
#nav-toggle.active span:before {
  transform: rotate(45deg);
}
#nav-toggle.active span:after {
  transform: rotate(-45deg);
}
