@labelCol: #FFF;

@inputCol: #FFF;
@backgroundCol: transparent;

@formFontSize: 1.2em;

@submitCol: @inputCol;
@submitBg: transparent;

@borderCol: @inputCol;

.border {
  margin-top: 10px;
}
.form-wrapper {
 background-color: @core-color__dark-green;
 padding-top: 10vh;
 padding-bottom: 5vh;
 .custom-select {
   background: url("../img/core/arrow.png") 97% 50% no-repeat @backgroundCol;
 }
}

.form-holder {
  width: 350px;
  float: left;
}
.error {
  color: red !important;
}
.form-title {
  width: 100%;
  color: @labelCol;
  height: 33px;
  padding: 7px 4px 0px 6px;
  margin-bottom: 2px;
}
.text-input,
.custom-select,
.custom-submit,
.custom-textarea {
  border-radius: 0px;
  appearance: none;
  height: 40px;
  width: 100%;
  border: 0;
  font-size: @formFontSize;
  font-family: @font-family-base;
  font-weight: 300;
  letter-spacing: 0.05em;
  background-color: @backgroundCol;
  color: @inputCol;
  outline: none;
  border-bottom: 1px solid @inputCol;
  margin-bottom: 25px;
  .serif;
}

.home-form-wrapper .text-input,
.home-form-wrapper .custom-select,
.home-form-wrapper .custom-submit,
.home-form-wrapper .custom-textarea {
  color: @core-color__grey;
  border-bottom: 1px solid @core-color__grey;
}
.home-form-wrapper .custom-textarea {
  border: 1px solid @core-color__grey;
}

.custom-textarea {
  height: 234px;
  border: 1px solid @inputCol;
  padding-top: 5px;
  padding-left: 5px;
}

.custom-select {
  padding-top: 4px;
  padding-left:5px;
  height: 40px;
  box-sizing: border-box;
  background: url("../img/core/arrow-grey.png") 97% 50% no-repeat @backgroundCol;
}

.custom-submit {
  color:@submitCol;
  box-shadow: none;
  margin-top: 10px;
  font-size: @formFontSize;
  border: 1px solid @inputCol;
  /*NEED EXTRA PADDING FOR ICON*/
  text-align: center;
  transition: 0.5s ease;
  .sans;
  text-transform: uppercase;
  padding-top:5px;
  &:hover {
     background-color: @core-color__dark-green;
     color: @core-color__lightblue;
     border:1px solid @core-color__lightblue;
  }
}

.enquire-now {
  .custom-submit {
    &:hover {
     background-color: @core-color__lightblue;
     color: @core-color__dark-green;
     border:1px solid @core-color__dark-green;
    }
  }
}

.home-form-wrapper .custom-submit {
  border: 1px solid @core-color__grey;
}



.form-container {
  width: 80%;
  margin: 0 auto;
}
.form-container h4 {
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px;
}
label {
  color: @labelCol;
  font-weight: bold;
}
::placeholder {
  color: #767676;
}

.form-field-left-half, .form-field-right-half, .form-field-full {
  float: left;
  width: 49%;
}
.form-field-right-half {
  float: right;

}
.form-field-full {
  width: 100%;
}
.form-field{
  clear: both;
}

.reg-form {
  margin-top: @spacing;
  margin-bottom: @spacing;
}

option {
  color :#000000 !important;
}

.submitting {
    text-align: center;
    display: none;
    margin-bottom: @spacing;
    img {
      width: 50px;
    }
}

.custom-checkbox[type="checkbox"] {
  appearance: none !important;
  background: transparent !important;
  border: 1px solid@core-color__white !important;
  width: 15px;
  height: 15px;
  border-radius: 0 !important;
  margin-top: 0px;
  color:@core-color__white !important;
  margin-right: 5px;
  vertical-align:middle;
  &:focus {
    outline: none;
  }
  &:after {
    content: '';
    position: relative;
    font-size: 0.7em;
    left: 4px;
    top: -4px;
  }
  &:checked {
    &:after {
      content: "\00d7";
      color: @core-color__white !important;
    }
  }
  &.ios {
    &:checked:after{
      top: -5px;
    }
  }
}

.checkbox-label {
  padding-top: 4px;
  font-size: 1em;
  color:@core-color__white;
}

.checkbox-holder {
  margin-bottom: 5px;
}


// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .form-field-left-half, .form-field-right-half {
    float: left;
    width: 100%;
  }
}

// xs styles
@media (max-width: 767px) {

}


/* ipad Landscape only*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {  }

/* ipad Portrait only*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {  }

//iphone landscape
@media only screen and (min-device-width : 320px) and (max-device-width : 668px) and (orientation : landscape) {  }

/* Phones */
@media only screen and (min-device-width : 320px) and (max-device-width : 668px) and (orientation : portrait) {

  .custom-submit {
    border: 1px solid @core-color__white;
    margin-top: 10px;
  }
 }