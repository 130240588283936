
.fancycontent { 
  display: none;
  background-color: @core-color__lightblue;
}

#tc {
  background-color: @core-color__lightblue;
}

.inner-fancy {
  width: 400px;
  height: 400px;
  color: @core-color__dark-green;
  background-color: @core-color__lightblue;
}

.fancylink {
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: none;
    color: white;
  }
}

@media only screen and (min-width : 320px) and (max-width : 1023px)  {
	.inner-fancy {
		width: 360px;
	}
}