#home-slider, #building-slider, #interior-slider, #shared-spaces-slider {
  width: 100%;
}
.royalSlider {
  width: 100%;
  height: auto;
}

.cap-black {
    color: #000;
}

@arrowHeight: 18px;
@arrowWidth: 10px;

.rsDefault.rsHor {
  .rsArrowLeft {
    .rsArrowIcn {
      background-image:url('/img/core/arrows/arrow-w-left.png');
      height: @arrowHeight;
      width: @arrowWidth;
      margin-top: 0;
      top: calc(~"(100% - @{arrowHeight}) / 2");
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
      background-position: top left;
      margin-left: 0;
      left: @arrowWidth;
      z-index: 1;
    }
  }
}
.rsDefault.rsHor {
  .rsArrowRight {
    .rsArrowIcn {
      background-image:url('/img/core/arrows/arrow-w-right.png');
      width: @arrowWidth;
      height: @arrowHeight;
      margin-top: 0;
      top: calc(~"(100% - @{arrowHeight}) / 2");
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
      background-position: top left;
      right: @arrowWidth;
      left: inherit;
      z-index: 1;
    }
  }
}


.rsDefault {
  .rsOverflow {
    background-color: transparent;
  }
}
.royalSlider .rsSlide img {
    opacity: 0.45 !important;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.royalSlider .rsActiveSlide img {
    opacity: 1 !important;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.rsDefault{
  .rsSlide {
    background-color: @core-color__dark-green;
  }
}



.rsDefault {
  .rsBullets {
    background-color: transparent;
    height: auto;
    bottom: 10px;
  }
  .rsBullet {
    padding: 10px 7px;
  }

  .rsGCaption {
    top: 0;
    bottom: auto;
    background-color: transparent;
    height: 100%;
    left: 0;
    width: 100%;
    pointer-events: none;
    cursor: grab;
    .banner-project-title {
      position: absolute;
      color: @core-color__white;
      bottom: 39%;
    }
    .banner-project-name {
      position: absolute;
      color: @core-color__white;
      bottom: 15%;
      .serif;
      h2 {
        font-size: 10vh;
      }
    }
  }
}

.rsContent {
  background-repeat: no-repeat;
  background-size: cover;
  h1,h2,h3 {
    .serif;
    margin: 0;
    font-weight: normal;
    font-size: 3em;
    line-height: 1.3em;
  }
  h4, h5 {
    .serif;
    margin: 0;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.3em;
  }
  .banner-button {
    background-color: @core-color__lightblue;
    text-align: center;
    padding: 4px 10px;
    display: block;
    float: left;
    color: @core-color__black;
    text-transform:uppercase;
    margin-top:20px;
    font-weight: 700;
    transition: all 0.5s ease;
    &:hover {
      text-decoration: none;
      background-color: @core-color__dark-green;
      color: @core-color__lightblue;
    }
  }
  .button-dark-bg {
    &:hover {
      border: 1px solid @core-color__lightblue;
    }
  }
}
.home {
  .banner-1 {
    background-image: url('/img/sliders/home-slider/1.jpg');
    .banner-content {
      padding-top: 5%;
      // float: left;
      // padding-left: 5%;
      float: right;
      padding-right: 5%;
    }
  }
  .banner-2 {
    background-image: url('/img/sliders/home-slider/2.jpg');
    .banner-content {
      padding-right: 5%;
      padding-top: 5%;
      float: right;
    }
  }
  .banner-3 {
    background-image: url('/img/sliders/home-slider/3.jpg');
    .banner-content {
      padding-left: 5%;
      padding-top: 5%;
      float: left;
    }
  }
  .banner-4 {
    background-image: url('/img/sliders/home-slider/4.jpg');
    .banner-content {
      padding-right: 5%;
      padding-top: 5%;
      float: right;
    }
  }
  .banner-5 {
    background-image: url('/img/sliders/home-slider/5.jpg');
    text-align: center;
    .banner-content {
       position: relative;
      top: 50%;
      transform: translateY(-50%);
      h2 {
        margin-top: 20px;
      }
    }
    .banner-button {
      margin: 30px auto;
      width: 130px;
      float:none;
    }
  }
  .banner-6 {
    background-image: url('/img/sliders/home-slider/6.jpg');
    .banner-content {
      padding-left: 5%;
      padding-top: 5%;
      float: left;
    }
  }
  .banner-7 {
    background-image: url('/img/sliders/home-slider/7.jpg');
    .banner-content {
      padding-right: 5%;
      padding-top: 5%;
      float: right;
    }
    //  .banner-content {
    //     padding-left: 5%;
    //     padding-top: 5%;
    //     float: left;
    //   }
  }
  .banner-8 {
    background-image: url('/img/sliders/home-slider/8.jpg');
    .banner-content {
      padding-left: 5%;
      padding-top: 5%;
      float: left;
    }
  }
  .banner-9 {
    background-image: url('/img/sliders/home-slider/9.jpg');
    .banner-content {
      padding-left: 5%;
      padding-top: 5%;
      float: left;
    }
  }

}



.banner-evermore-logo {
  width: 430px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  opacity: 0;
  top: 171px;
  pointer-events: none;
}

.banner-title {
  width: 100%;
  height: 22px;
  text-align: center;
  position: absolute;
  bottom: 30px;
  .serif;
  color: @core-color__white;
  text-align: center;
  text-transform: uppercase;
  h2 {
    font-size: 2.3em;
    margin-bottom: 0;
  }
  h3 {
   margin: 0;
   letter-spacing: 3px;
  }
}
.below-slider-copy {
  position: absolute;
  width: 100%;
  bottom: -40px;
  color: @core-color__grey;
  text-align: center;
  .sans;
}

.banner-5 {
  .banner-title {
    top: 48%;
    bottom: auto;
  }
}

.rsGCaption {
  transition: opacity  0.2s;
}

.fadeOut {
  opacity: 0;
}
.fadeIn {
  opacity: 1;
}

@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {
  #menu-icon-holder {
    position: absolute;
    right: 25px;
    top: 27px;
  }
  .evermore-brand {
    margin-bottom: @spacing /2;
  }
  .royalSlider {
    width: 100%;
    height: 600px;
  }
}

/* Phones */
@media only screen and (min-width : 320px) and (max-width : 1024px)  {
  .royalSlider {
    width: 100%;
    height: 50vh;
  }
  .home {
    .royalSlider {
      height: 50vh;
    }
  }
  .mobile-slider-wrapper {
    padding: 0;
  }
  #home-slider-mobile {
    h3 {
      text-align: center;
      margin-top: 40px;
    }
  }
  .rsContent {
    h1,h2,h3 {
      font-size: 2em;
    }
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  .royalSlider {
    height: 50vh;
  }
}