// FONTS
@font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-serif;

// COLOURS
@core-color__default:     #000000;

@core-dropkick-bg:        #d9d9d2;
@core-dropkick-col:       #000000;


@core-color__cream:     #d0b3a1;
@core-color__grey:      #5d625c;
@core-color__copy:      #e3e2da;
@core-color__default:   @core-color__grey;
@core-color__off-white: #e0ddd6;
@core-color__white:     #ffffff;
@core-color__black:     #000000;
@core-color__bronze:    #904f32;
@core-color__peach:     #d0b3a1;

// tower 2
@core-color__lightblue: #c7dad8;
@core-color__dark-green: #00230e; // old green #3f4938

@core-color__background: @core-color__lightblue;

@menu-height: 100px;
@spacing: 6vh;

.svgLogo {
    height: 100%;
}

.sub-head {
  letter-spacing: 1px;
}

.main-copy {
  font-size: 0.9em;
  letter-spacing: 1px;
  line-height: 1em;
}

.button__green {
    background-color: @core-color__dark-green;
    color: @core-color__white;
    text-align: center;
    margin-top: 2px;
    padding: 3px;
    //transition: all 1s ease;
    &:hover {
      text-decoration: none;
      background-color: @core-color__lightblue;
      color: @core-color__dark-green;
      border: 1px solid @core-color__dark-green;
    }
}

.button-popup__green {
  .button__green;
  background-color: @core-color__lightblue;
  color: @core-color__dark-green;
  width: 50%;
  margin: 0 auto;
  margin-top: 5px;
  font-weight: 700;
  &:hover {
    background-color: @core-color__dark-green;
    color: @core-color__lightblue;
    border: 1px solid @core-color__lightblue;
  }
}

@mobile-menu-height: 110px;
@indent-space: 30px;