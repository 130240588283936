.floorplan-offset {
  margin-top: 200px;
}
.floorplan-holder {
  margin-top: 100px;
  img {
    max-width: 100%;
  }
}
.fp-outer-wrapper {
  margin-bottom: 100px;
}
.floorplan-button {
  background-color: @core-color__dark-green;
  text-align: center;
  padding: 4px 10px;
  display: block;
  // float: left;
  margin: 0 auto;
  width: 200px;
  color: @core-color__lightblue;
  text-transform: uppercase;
  font-weight: 700;
  transition: all .5s ease;
  border: 1px solid @core-color__dark-green;
  &:hover {
    text-decoration: none;
    background-color: @core-color__dark-green;
    color: @core-color__lightblue;
    border: 1px solid @core-color__dark-green;
  }
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .floorplan-title {
    margin-top: 60px;
  }

}

// xs styles
@media (max-width: 767px) {

}

