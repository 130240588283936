@import (less) "variables.less";
@import (less) "fonts.less";
@import (less) "normalize.css";
@import (less) "../node_modules/bootstrap/dist/css/bootstrap.css";
@import (less) "menu.less";
@import (less) "footer.less";
@import (less) "fancy-menu.less";

@import (less) "isotope.less";

@import (less) "../js/vendor/royalslider/royalslider.css";
@import (less) "../js/vendor/royalslider/skins/default/rs-default.css";
@import (less) "slider.less";

@import (less) "dropkick.less";
@import (less) "form.less";

@import (less) "home.less";
@import (less) "floorplans.less";
@import (less) "popup.less";
@import (less) "lang.less";
@import (less) "mobile-menu.less";
@import (less) "animation.less";
@import (less) "fancybox.less";

/* CORE */

a[href^="x-apple-data-detectors:"] {
    color: @core-color__default;
    text-decoration: none;
}
a[href^="tel"], a[href^="sms"] {
    color: @core-color__default;
    text-decoration: none;
}

html, body {
    margin: 0;
    padding: 0;
    background-color: @core-color__background;
    color: @core-color__grey;
}

* {
    &:focus {
        outline: 0 none;
    }
}

a, img {
    border:none;
    outline:none
}


/*Safari Targeting */

_::-webkit-full-page-media, _:future, :root .safari-only {
 margin-top: 1px;

}

.no-left-padding {
  padding-left: 0;
}

.col-centered{
    // need important for ie8
    float: none !important;
    margin: 0 auto;
}
.grey-hr {
  border-color: @core-color__grey;
  margin-top: 0;
}
.text-uppercase {
  text-transform: uppercase;
}
.font-weight-normal {
  font-weight: normal;
}
.larger-line-height {
  line-height: 30px;
}

iframe[name='google_conversion_frame'] {
    height: 0 !important;
    width: 0 !important;
    line-height: 0 !important;
    font-size: 0 !important;
    margin-top: -13px;
    float: left;
}

.rotate-dialog {
    display: none;
}
.no-margin {
  margin: 0;
}

.spacer {
  margin-bottom: @spacing;
}

.spacer-home {
  margin-bottom: 4vh;
}

.spacer-sml {
  margin-bottom: 2vh;
}

.home-copy-holder {
  h2,h3,h4 {
    margin: 0;
  }
  h3 {
    font-size: 1.5em;
    line-height: 1.3em;
  }
}
.main-copy {
  font-size: 0.95em;
  line-height: 1.3em;
}
.sub-head {
  font-size: 1.3em;
}
.indent-space {
  padding-left: @indent-space;
}
.section-title {
  letter-spacing: 2px;
}
.copy-enquire {
  text-transform: uppercase;
  color: @core-color__grey;
  letter-spacing: 1px;
  font-size: 1.05em;
  text-align: center;
  &:hover {
    color: @core-color__grey;
    text-decoration: none;
  }
}

.button__green__home {
  background-color: @core-color__dark-green;
  text-align: center;
  padding: 4px 10px;
  display: block;
  float: left;
  margin: 0 auto;
  width: 200px;
  color: @core-color__lightblue;
  text-transform: uppercase;
  font-weight: 700;
  transition: all .5s ease;
  border: 1px solid @core-color__dark-green;
  &:hover {
    text-decoration: none;
    background-color: @core-color__dark-green;
    color: @core-color__lightblue;
    border: 1px solid @core-color__dark-green;
  }
}


.map-holder {
  overflow: hidden;
  height: 67vh;
  img {
    width: 100%;
    height: auto;
    left:0 ;
    top: -431px;
  }
}
.team-banner {
  img {
    width: 100%;
  }
}
.team {
  .section-title {
    margin-bottom: @spacing / 2;
  }
}
.team-landence-logo {
  float: left;
  height: 80px;
  margin-top: @spacing;
}
.team-collaborator-wrapper {
  height: 100px;
}
.team-lu {
  height: 40px;
}
.colaborators {
  a {
    color: @core-color__grey;
    text-decoration: none;
    .serif;
  }
}
.team-logos {
  margin-bottom: @spacing /2;
  margin-top: @spacing /2;
}

.team-hayball-logo {
  height: 30px;
}

.team-tract-logo {
  height: 25px;
  padding-top: 4px;
}
.team-gallagher-logo {
  height: 28px;
  padding-top: 7px;
}
.thankyou {
  padding-top: @spacing;
}

.no-top-margin {
  margin-top: 0;
}
.enquire-now {
  .form-wrapper {
    h2,h3,h4, p {
      color: @core-color__white;
    }
    h3 {
      letter-spacing: 2px;
      font-weight: normal;
    }
  }
  .grey-hr {
    display: none;
  }
}
.form-copy {
  p {
    font-size: 0.9em;
    letter-spacing: 1px;
  }
}
.mobile-only {
  display: none;
}
.header-logo-class {
  max-width: 100%;
}

.center-text {
  text-align: center;
}

/* ipad Landscape only*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape)  {
  // .mobile-header {
  //   display: none !important;
  // }
  .map-holder {
    display: none;
  }
 }



//iphone landscape
@media only screen and (min-device-width : 320px) and (max-device-width : 765px) and (min-resolution: 1.4dppx) and (min-device-aspect-ratio: 13/9)   {
    // .page-wrapper {
    //     display: none;
    // }

    // .rotate-dialog {
    //    display: block;
    //    width: 100px;
    //    position: absolute;
    //    left: 50%;
    //    top: 50%;
    //    margin-right: -50%;
    //    transform: translate(-50%, -50%);

    //    .st0 {
    //        width: 100%;
    //        fill: #000;
    //    }
    // }
}

/* Phones */
@media only screen and (min-width : 320px) and (max-width : 1023px)  {
  .mobile-only {
    display: block;
  }


  .slider_head, .desktop-menu-holder, .retreat-everyday, .desktop-only, .footer-nav, {
    display: none;
  }

  .team-banner {
    margin-left: -15px;
    margin-right: -15px;
  }

  .mobile-cta {
    display: block;
    background-color: @core-color__dark-green;
    padding: 13px;
    color: @core-color__white;
    margin-bottom: @spacing;
    margin-top: @spacing;
    text-align: center;
    h3 {
      margin:0;
      padding: 0;
      font-size: 1.1em;
      letter-spacing: 2px;
    }
    &:active, &:focus, &:hover {
      color: @core-color__white;
      text-decoration: none;
    }
  }
  .home {
    .mobile-cta {
      h3 {
        font-size: 0.8em;
      }
    }
  }

  .evermore-brand {
    display: block;
    margin-top: @spacing / 2;
    max-height: 52px;
  }
  .mobile-logo {
    max-width: 100%;
    max-height: 48px;
  }
  .retreat-mobile {
    margin-top: @spacing;
    margin-bottom: @spacing;
  }
  .menu-offset {
    margin-top: @mobile-menu-height;
  }
  .home-copy-holder {
    font-size:1.5em;
  }

  .mobile-indent-space {
    padding-left: @indent-space;
  }
  .section-title {
    h3 {
        padding-left: @indent-space + 15px;
    }
  }

  .now-selling-mobile-strip {
    background-color: @core-color__grey;
    text-align: center;
    margin-bottom: 20px;
    .mobile-cta {
      margin: 0;
    }
    a {
      color: @core-color__white;
      font-size: 1.2em;
    }
  }

  .team-landence-logo {
    padding-left: 45px;
  }


}

@media only screen and (orientation : landscape) {

  .mobile-logo {
    height: 50px;
  }
}



// NEED TO OVERIDE ON iPAD portrait only... argh

/* ipad Portrait only*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  //  THOUGHT ABOUT IT... 10:23pm
  // .page-wrapper {
  //     display: none;
  // }

  // .rotate-dialog {
  //    display: block;
  //    width: 100px;
  //    position: absolute;
  //    left: 50%;
  //    top: 50%;
  //    margin-right: -50%;
  //    transform: translate(-50%, -50%);
  //    .st0 {
  //        width: 100%;
  //        fill: #000;
  //    }
  // }
  .retreat-mobile {
      margin-top: 0;
      width: 50%;
  }
  .landence-logo {
    float: left;
    clear: both;
    margin-top: @spacing / 2;
  }
 }

//Menu offset fix

.building, .interiors, .shared-spaces, .location, .team {
  .subpage-menu-offset {
    margin-top: 1px;
  }
}

.team-offset {
  padding-left: 15px;
}

.team-heading-offset {
  padding-left: 45px;
}


