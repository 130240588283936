.footer-container {
  margin-bottom: @spacing /2 ;
  h3 {
    // font-size: 1.5em;
    margin-bottom: 30px;
    letter-spacing: 2px;
  }
}
.footer-nav {
  margin-bottom: @spacing /2 ;
  h4 {
    margin-top: @spacing /3;
    letter-spacing: 2px;
  }
  a {
    color: @core-color__grey;
    &:hover {
      text-decoration: none;
    }
  }
  p {
    font-style: italic;
    line-height: 1.2em;
  }
  img {
    width: 100%;
  }
}
.footer-details {
  p {
    font-size: 1.1em;
    margin-bottom: 0;
    letter-spacing: 1px;
  }
}

.dev-by {
  width: 80%;
  float:left;
  //text-align: right;
  padding-right: @spacing;
  padding-left: 5vh;
}
.landence-logo {
  width: 20%;
  float: right;
}

/* Phones */
@media only screen and (min-width : 320px) and (max-width : 765px) and (orientation : portrait) {
  .dev-by {
    width: 55%;
    //text-align: left;
    padding-right: 0;
    padding-left: 0;
  }
  .landence-logo {
    float: right;
    margin-right: @spacing;
  }

  .footer-details {
    p {
      margin-bottom: 24px;
    }
  }
}
@media only screen and (min-width : 320px) and (max-width : 1023px) and (orientation : landscape)  {
  .landence-logo {
    width: 8%;
    float: left;
    padding-top: 10px;
  }
  .dev-by {
    width: 52%;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  .dev-by {
    padding-right: 0;
    padding-left: 0;
  }
}