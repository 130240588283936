.home{
  .banner-1, .banner-2, .banner-3, .banner-4, .banner-6 {
    .banner-title {
      top: 50% ;
      transform: translateY(-50%) ;
      bottom: auto ;
      height:auto;
      width: 50%;
      right: 0;
        p.sans{
          font-size:1.5em ;
          padding-top:2em;
        }
    }
  }
  .banner-2, .banner-3, .banner-4, .banner-6 {
    .banner-title{
      width: 100%;
      right: 0;
      left: 0;
    }
  }
  .font-normal {
    font-weight: normal;
  }
  .smaller {
    font-size: 0.7em;
    display: block;
    margin-bottom: -10px;
  }
  .banner-5 {
    .banner-title{
      // top: 48% !important;
      // bottom: auto !important;
    }
  }

  .banner-6 {
    .banner-title{
      top: 48% !important;
      bottom: auto !important;
    }
  }
  .product-offering {
    padding-left: 0;
  }
  .product-pricing {
    line-height: 20px;
    margin-top: @spacing;
  }
}

.home-title {
  font-size:2.4em;
  font-style: italic;
  .serif
}

.home-blocks {

  .bottom-row {
    margin-bottom: 30px;
  }
  .image-button {
    img {
      width: 100%;;
    }
    a {
      text-decoration: none;
    }
  }

  .shared-spaces {
    height: 349px;
    background-image: url('/img/home/btn-shared-spaces.jpg');
    background-repeat: no-repeat;
    &:hover {
      text-decoration: none;
    }
  }

  .video {
    height: 349px;
    background-image: url('/img/home/btn-video.jpg');
    background-repeat: no-repeat;
    &:hover {
      text-decoration: none;
    }
  }

  .dark-green-bg {
    height: 349px;
    background-color: @core-color__dark-green;
    background-repeat: none;
  }

  .banner-button {
    background-color: #c7dad8;
    text-align: center;
    padding: 4px 10px;
    display: block;
    float: left;
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    position: absolute;
    left: 67px;
    top: 220px;
    &:hover {
      text-decoration: none;
      background-color: @core-color__dark-green;
      color: @core-color__lightblue;
      border: 1px solid @core-color__lightblue;
    }
  }
}

.home-blocks-copy {
  .serif;
  color: @core-color__white;
  margin: 0;
  font-weight: normal;
  font-size: 1.8em;
  line-height: 1.3em;
  position: absolute;
  &:hover {
    text-decoration: none;
  }
}

.home-blocks-copy__green {
  .home-blocks-copy;
  transform: translate(0,-50%);
  top: 43%;
  left: 65px;
}

.home-blocks-copy__image {
  .home-blocks-copy;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 297px;
}

.home-blocks-copy__video {
  .home-blocks-copy;
  transform: translate(-50%,-50%);
  top: 40%;
  left: 297px;
}

.offset-lang {
  left: 65px
}

.play-wrapper {
  width: 60px;
  position: absolute;
  top: 47%;
  left: 262px;
}

.link {
  color: @core-color__grey;
  &:hover {
    color: @core-color__grey;
  }
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .home-copy-holder {
    .home-title {
      font-size: 2em;
      margin-bottom: 40px;
    }
  }
  .home {
    .product-offering {
      padding-left: 15px;
    }
  }
  .home{
    .banner-1 {
      .banner-title {
       transform: none;
       top: auto ;
       width: 100%;
       bottom: 90px;
      }
    }
    .banner-5 {
      .banner-title {
       top: 50% ;
       transform: translateY(-50%);
       width: 100%;
       bottom: auto;
       height: auto;
     }

    }
  }
  #home-slider-mobile{
    .banner-5 {
        .banner-title {
          h3 {
          margin-top: 0;
          }
        }
    }
  }
  .image-button {
    margin-bottom: 30px;
  }
  .home-blocks {
    .banner-button {
      left: 46px;
    }
  }
  .home-blocks-copy__image,
  .home-blocks-copy__video  {
    width: 100%;
    text-align:center;
    font-size: 1.5em;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    transform: translateX(0);
  }
  .home-blocks-copy__green {
    left: 46px;
    font-size: 1.5em;
  }
  .home-blocks-copy__video {
    // left: 200px;
  }
  .play-wrapper {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

// xs styles
@media (max-width: 767px) {

}