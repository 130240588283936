#display-popup{
  background-color: @core-color__dark-green;
  color: @core-color__lightblue;
  max-width: 540px;
  position: relative;
  margin: 20px auto;
  padding: 40px;
  text-align: center;
  .home-title  {
    .text-uppercase;
    margin-bottom: 20px;
    font-style: normal;
    letter-spacing: 2px;
  }
  img {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

#chinese-popup {
  max-width: 900px;
  background-color: @core-color__lightblue;
  color: @core-color__grey;
  position: relative;
  margin: 20px auto;
  padding: 40px;
  text-align: center;
  p {
    font-size: 18px;
  }
  .custom-select, .custom-submit, .custom-textarea, .text-input {
    color: @core-color__grey;
    border-color: @core-color__grey;
  }
  .mfp-close {
    color: @core-color__grey;
  }
  .home-title  {
    .text-uppercase;
    margin-bottom: 20px;
    font-style: normal;
    letter-spacing: 2px;
    font-size: 28px;
    font-weight: bold;
  }
}

//close button
.mfp-close-btn-in {
  .mfp-close {
    color: #c7dad8;
  }
}