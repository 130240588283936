@import (less) "1608-UAMBSQ.css";

// FONTS
@font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

@core-font__serif: 'Canela Web', serif;


.serif {
  font-family: @core-font__serif;
  letter-spacing: 1px;
}
.sans {
  font-family: @font-family-sans-serif;
}