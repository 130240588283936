.svg-evermore {
  opacity: 0;
  // animation: startOnTurnOff  6s infinite;
}

.svg-tower-2 {
  opacity: 1;
  // animation: startOffTurnOn 6s infinite;
}

@keyframes startOnTurnOff {
   0% {
     opacity: 1;
   }
   35% {
     opacity: 1;
   }
   50% {
     opacity: 0;
   }
   85% {
     opacity: 0;
   }

}

@keyframes startOffTurnOn {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
}