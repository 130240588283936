@heading-height: 46px;

.fixed-active {
  .fixed-top-heading {
    top: 0;
    opacity: 1;
  }
  .menu-holder {
    top: @heading-height;
  }
  .menu-offset {
    margin-top: @menu-height + @heading-height;
  }
}

.rel-con {
  position: relative;
}
.fixed-top-heading {
  top: -@heading-height;
  height: @heading-height;
  background-color: @core-color__dark-green;
  transition: 1s ease top, 0.1s ease opacity;
  position: fixed;
  width: 100%;
  text-align: center;
  z-index: 100;
  opacity: 0;
  p {
    color: #fff;
    margin-top: 12px;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
  }
}
.fixed-header-closer {
  position: absolute;
  right: 0;
  color: #fff;
  top: 7px;
  font-size: 1.5em;
}

.menu-holder {
  position: fixed;
  width: 100%;
  top: 0px;
  padding-top: 15px;
  height: auto;
  background-color: @core-color__background;
  z-index: 1000;
  transition: 1s ease all;
  a {
    color: @core-color__grey;
  }
  .menu-enquire-button {
    background-color: @core-color__dark-green;
    color: @core-color__white;
    text-transform: uppercase;
    display: block;
    padding: 6px 10px 5px 10px;
    text-align: center;
    letter-spacing: 1px;
    font-size: 1.3em;
    font-weight: bold;
    width: 95%;
    margin-left: 15px;
  }
  .menu-underline {
    border-bottom: 1px solid @core-color__grey;
    padding: 0;
  }
  .menu-offset-underline {
    margin-top: 0;
  }
}
.logo-holder {
  padding-left: 0;
}

// .home {
//   .menu-offset-underline {
//     margin-top: 0;
//   }
// }

.east-tower-wrapper {
  svg {
    width: 86%;
  }
}


.menu-items-wrapper__left {
  margin-left: 15px;
  margin-right: -15px;
}
.menu-items-wrapper__right {
  margin-left: -15px;
  margin-right: 15px;
}
.menu-offset {
  transition: 1s ease all;
  margin-top: @menu-height;
}
.menu-wrapper {
  margin-top: @spacing /2;
  margin-bottom: @spacing /2;
}



.menu-horizontal {
  list-style: none;
  padding: 0;
  margin: 10px 10px 10px 0;
  text-align: left;
  float: left;
  font-size: 0.85em;
  letter-spacing: 1px;
  li {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin-right: 15px;
    text-transform: uppercase;
    a {
      transition: 0.5s ease;
      text-decoration: none;
      color: @core-color__grey;
      &:hover {
        transition: 0.5s ease;
        text-decoration: none;
      }

    }
    &:last-child {
      margin-right: 0px;
    }
  }
}

a.menu-enquiry-item {
  display: block;
  float: right;
  text-decoration: none;
  margin-left: 3vh;
  color: @core-color__grey;
}

div.menu-enquire-now {
  background-color: @core-color__dark-green;
  color: @core-color__white;
  display: block;
  font-weight: bold;
  padding: 7px 50px 7px 16px;
  font-size: 1em;
  text-transform: uppercase;
  width: 175px;
  box-sizing: border-box;
  .sans;
  transition: 0.5s ease;
  &:hover {
    background-color: @core-color__white;
    color: @core-color__dark-green;
  }
}

div.menu-enquire-now svg {
  width: 40px;
  height: 24px;
  position: absolute;
  right: 24px;
  top: 6px;
  fill: @core-color__white;
  transition: 0.5s ease;
}

div.menu-enquire-now:hover svg {
  fill: @core-color__dark-green;
}

.active {
  .serif;
  font-style: italic;
  text-decoration: underline;
}

.register-motif {
  float: right;
  width: 7%;
  height: 34px;
}

.enquire-now {
  .register-motif {
    svg {
      path {
       fill: @core-color__peach !important;
       fill: @core-color__peach !important;
      }
    }
  }
}



.language-select{
  font-size: 0.75em;
  text-transform: uppercase;
  text-align: right;
  padding: 5px 0;
  border-bottom: 1px solid @core-color__grey;
  letter-spacing: 1px;

}

@media only screen and (max-width: 1025px) and (min-width: 320px) {
  .menu-offset {
    /*margin-top: 80px !important;*/
    //margin-top:150px !important;
  }
  .language-select{
    padding: 5px 20px;
  }
  .mobile-menu-active{
    top: 106px;
  }
  .mobile-logo-hanmburger-wrapper{
    height: 106px;
    transition: 1s ease top, 0.1 ease opacity;
  }
  #menu-icon-holder{
    top: 40px;
    top: 6vh;
  }
  .menu-offset {
    .spacer {
      margin-bottom: 0;
    }
  }


  @heading-height: 60px;

  .fixed-active {
    .mobile-logo-hanmburger-wrapper {
      top: @heading-height;
    }
    .menu-offset {
      margin-top: @menu-height + @heading-height;
    }
  }
  .fixed-top-heading {
    top: -@heading-height;
    height: @heading-height;
    p {

      font-size: 1em;
    }
  }
  .fixed-header-closer {
    right: 10px;
    top: 4px;
  }
}

@media only screen and (min-width : 768px) and (max-width : 1200px),
       only screen and (min-width : 768px) and (max-width : 1200px) and (orientation : landscape)  {

  .menu-horizontal {
    li {
      margin-right: 8px;
    }
  }
  .menu-enquire-now {
    margin-left: 1vw;
  }

}


