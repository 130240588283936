@gutter-size: 2%;

.isotope-grid {

}
.isotope-item {
  // margin-right: @gutter-size;
  margin-bottom: 20px;
  overflow: hidden;
  img {
    width: 100%;
  }
}

.isotope-grid-item-25 {
  width: calc(~"(25% - @{gutter-size})");
  float: left;
}

.isotope-grid-item-50 {
  width: calc(~"(50% - @{gutter-size})");
  float: left;
}
.isotope-copy-block {
  padding: @gutter-size;
  background-color: @core-color__dark-green;
  color: @core-color__white;
  h2,h3 {
    margin-top: 0;
    margin-bottom: 20px;
    .serif;
    text-transform: uppercase;
  }
  p {
    font-size: .95em;
    line-height: 1.3em;
    letter-spacing: 1px;
  }
}

.isotope-filter-wrapper {
  width: 80%;
  margin-bottom: @spacing;
  margin-left: auto;
  margin-right: auto;
  a {
    color: @core-color__grey;
    text-decoration: none;
  }
  .isotope-active {
    color: @core-color__dark-green;
    text-decoration: none;
  }
}
.isotope-filter {
  width: 33%;
  text-align: center;
  display: block;
  float: left;
}

.isotope-caption {
  height: 137px;
  width: 100%;
  background-color: rgba(93,98,92,0.9);
  bottom: -173px;
  color: @core-color__white;
  padding: 15px;
  transition: 0.5s all ease;
  position: absolute;
}
.isotope-caption-hover {
  bottom: 0;
}
.isotope-suburb, .isotope-web  {
  .indent-space;
}

.isotope-title {
  margin-bottom: 15px;
}
.isotope-suburb {
  p {
    margin-bottom: 0;
  }
}

.isotope-caption-ls {
  float: left;
  width: 70%;
   a {
      color:  @core-color__white;
      .serif;
      font-style: italic;
      text-decoration: none;
   }
   h4 {
    margin: 0;
   }
}

.isotope-caption-rs {
  float: right;
  width: 18%;

}
.isotope-distance-marker {
  background-color: @core-color__white;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  color: @core-color__grey;
  padding-top: 1px;
  text-align: center;

  position: absolute;
  right: 15px;
  top: 15px;
  h4 {
    font-size: 0.9em;
  }
}